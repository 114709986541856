// src/pages/AccountPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/AccountPage.css'; // Ensure this path is correct
import config from '../config';

const AccountPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [joined, setJoined] = useState('');
  const [nfcUID, setNfcUID] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/user/account`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        /*
        const response = await axios.get('http://localhost:5000/api/user/account', {
          headers: { Authorization: `Bearer ${token}` },
        });
        */
        const userData = response.data;
        setName(userData.name);
        setEmail(userData.email);
        setNfcUID(userData.nfcUID || '');
        setJoined(new Date(userData.joined).toLocaleDateString());
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/user/updateProfile`,
        {
          name,
          email,
          nfcUID: nfcUID || null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setSuccessMessage('Profile updated successfully!');
        setIsEditing(false);
        setError('');
        // Optionally, clear the success message after some time
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
      setSuccessMessage('');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDeleteAccount = () => {
    navigate('/delete-account');
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setError('');
    setSuccessMessage('');
  };
/*
  const toggleSettingsMenu = () => {
    setShowSettingsMenu(!showSettingsMenu);
  };
*/


const handleCogClick = () => {
  setShowSettingsMenu((prev) => !prev);
};

/*
  const handleCogClick = () => {
    setShowSettingsMenu(!showSettingsMenu);
    const cogElement = document.querySelector('.settings-cog');
    if (showSettingsMenu) {
      cogElement.classList.remove('rotate');
    } else {
      cogElement.classList.add('rotate');
    }
  };
*/

  return (
    <div className="account-page">

{/*
      
      <div className="account-container">
        <h2 className="account-heading">Account Information</h2>

        {error && <div className="Account-update-message error-message">{error}</div>}
        {successMessage && <div className="Account-update-message success-message">{successMessage}</div>}
  
      */}

<div className="account-header">
        <h2 className="account-heading">Account Information</h2>
        <div
          className={`settings-cog ${showSettingsMenu ? 'rotate' : ''}`}
          onClick={handleCogClick}
        >
        {/*<div className={`settings-dropdown ${showSettingsMenu ? 'show' : ''}`}>*/}
        {/*<div className="settings-cog" onClick={toggleSettingsMenu}>*/}
          ⚙️ 
       {/*
        </div> {showSettingsMenu && (
          <div className="settings-dropdown">
            <button onClick={handleEditToggle} className="dropdown-edit-account-button">Edit Profile</button>
            <button onClick={handleDeleteAccount} className="delete-account-button">Delete Account</button>
            <button onClick={() => navigate('/change-password')} className="change-password-button">Change Password</button>
          <button onClick={() => navigate('/notification-settings')} className="notification-settings-button">Notification Settings</button>
          <button onClick={() => navigate('/privacy-settings')} className="privacy-settings-button">Privacy Settings</button>
          <button onClick={() => navigate('/account-security')} className="account-security-button">Account Security</button>
          </div>
        )}
      </div>
    */}
        
        </div>
<div className={`settings-dropdown ${showSettingsMenu ? 'show' : ''}`}>
  <button onClick={handleEditToggle} className="dropdown-edit-account-button">Edit Profile</button>
  <button onClick={handleDeleteAccount} className="delete-account-button">Delete Account</button>
  <button onClick={() => navigate('/change-password')} className="change-password-button">Change Password</button>
  <button onClick={() => navigate('/notification-settings')} className="notification-settings-button">Notification Settings</button>
  <button onClick={() => navigate('/privacy-settings')} className="privacy-settings-button">Privacy Settings</button>
  <button onClick={() => navigate('/account-security')} className="account-security-button">Account Security</button>
</div>
</div>
       
      <div className="account-container">
        {error && <div className="Account-update-message error-message">{error}</div>}
        {successMessage && <div className="Account-update-message success-message">{successMessage}</div>}


        <form className="account-form" onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            {isEditing ? (
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            ) : (
              <p className="form-value">{name}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            {isEditing ? (
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            ) : (
              <p className="form-value">{email}</p>
            )}
          </div>

          <div className="form-group">
          <label htmlFor="nfcUID" className="CardID-form-label-wide">Card ID:</label>
            {/*<label htmlFor="nfcUID">Card ID:</label>*/}
            {isEditing ? (
              <input
                type="text"
                id="nfcUID"
                value={nfcUID}
                onChange={(e) => setNfcUID(e.target.value)}
                placeholder="Enter NFC UID"
              />
            ) : (
              <p className="form-value">{nfcUID || 'Not linked'}</p>
            )}
          </div>

          <div className="joined-date">
            <label>Joined:</label>
            <p className="form-value">{joined}</p>
          </div>

          <div className="button-group">
            {isEditing ? (
              <>
                <button type="button" className="Account-save-button" onClick={handleSave}>
                  Save Changes
                </button>
                <button type="button" className="Account-cancel-button" onClick={handleEditToggle}>
                  Cancel
                </button>
              </>
            ) : (
              <button type="button" className="Account-edit-button" onClick={handleEditToggle}>
                Edit Profile
              </button>
            )}
          </div>
        </form>

        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default AccountPage;
